import * as React from "react";
import {useEffect, useState} from "react";
import "aos/dist/aos.css";
import { Home } from "../components/layouts/Home/Home";
import { Hero } from "../components/hero/hero";
import TwoColumns from "../components/TwoColumns/TwoColumns";
import styled from "styled-components";
import { constants } from "../fonts/constants/constants";
import { MonitorWrapper } from "../components/MonitorWrapper/monitorWrapper";
import Button from "../components/Button/button";
import { Footer } from "../components/Footer/Footer";
import CookieConsent from "react-cookie-consent";
import serverRelated from "../config/client";
import ShorterFcTeaser from "../assets/videos/shorter_fc_teaser.mp4";
import Leaf7 from "../assets/leaf7-min.svg";
import PosterVideo from "../assets/poster-video.jpg";
import * as styles from "../scss/pages/index.module.sass";
import { SEO } from "../components/Seo/seo";

const { ApolloProvider, client } = serverRelated;

export const IndexPage = ({ location }: any) => {
  const [isDesktop, setisDesktop] = useState(false)
  let AOS: any;
  
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      disable: function () {
        var maxWidth = 200;
        return window.innerWidth < maxWidth;
      },
    });
    getRefByUrl();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setisDesktop( window?.innerWidth >= 1600)
    });
    setisDesktop( window?.innerWidth >= 1600)
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  const getRefByUrl = () => {
    const params = new URLSearchParams(location.search);
    const ref: any = params.get("ref");
    if (ref) {
      localStorage.setItem("ref", ref);
    } else {
      localStorage.removeItem("ref");
    }
  };

  return (
    <ApolloProvider client={client}>
      <Home>
        <Hero />
        <h3 id="theMagic" className={styles.titleMagic}>
          The Magic<span>.</span>
        </h3>
        <section
          id="usdAccounts"
          className={`${styles.section} ${styles.usdAccounts}`}
        >
          <TwoColumns
            img="6"
            data={constants.usdAccounts}
            onDesktopPositionTextTo="left"
          />
        </section>
        <section id="comissionFree" className={styles.section}>
          <TwoColumns leaf="1" img="2" data={constants.comissionFree} />
        </section>
        <section id="piesAndSlices" className={styles.section}>
          <TwoColumns
            leaf="5"
            img="1"
            data={constants.piesAndSlices}
            onDesktopPositionTextTo="left"
          />
          <img src={Leaf7} alt="" className={styles.leaf7} />
        </section>
        <section id="fractionalShares" className={styles.section}>
          <CenterTextWrapper>
            <h3 className={styles.titleFractionalShares}>
              Fractional Shares<span>.</span>
            </h3>
            <p className={styles.fractionalSharesP}>
              Invest as little as $1 USD into more than 5,000 securities!
            </p>
            <p className={styles.fractionalSharesP}>
              Diversify your portfolio with fractional shares and take control
              of your investments.
            </p>
          </CenterTextWrapper>
          <div className={styles.MonitorWrapper}>
            <MonitorWrapper mustShowGradient={isDesktop}/>
          </div>
        </section>

        <section
          id="education"
          className={`${styles.education} ${styles.section}`}
        >
          <h3 className={styles.titleEmpowered}>
            We’re all about{" "}
            <span className={styles.bold}>
              empowered investing<span>.</span>
            </span>
          </h3>
          <TwoColumns
            leaf="4"
            data={constants.learn}
            video={{
              src: ShorterFcTeaser,
              type: "video/mp4",
              poster: PosterVideo,
            }}
          />
        </section>

        <QuestionSection>
          <p>Do you have more questions?</p>
          <div className="question-button">
            <a href={"https://help.flahmingo.com"}>
              <Button>Learn more</Button>
            </a>
          </div>
        </QuestionSection>

        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Okay, got it"
          cookieName="flahmingo-cookie"
          containerClasses="cookieWrapper"
          buttonWrapperClasses="buttonWrapper"
          buttonStyle={{
            background: "#ff63af",
            color: "#ffffff",
            padding: "16px 24px",
            borderRadius: "30px",
          }}
          contentStyle={{ flex: "inherit" }}
        >
          <p className="cookieText">
            This site uses cookies from Flahmingo to deliver its services and to
            analyze traffic.
          </p>
        </CookieConsent>
      </Home>
    </ApolloProvider>
  );
};

const QuestionSection = styled.div`
  border-radius: 30px;
  padding: 16px 2rem;
  margin: 48px 10vw 0;
  background: linear-gradient(280.54deg, #4947ab 7.06%, #9290f2 91.14%);
  @media (min-width: 1024px) {
    margin-top: 120px;
  }
  question-button.button {
    background: #ffffff;
  }

  h2 {
    padding-top: 40px;
    font-size: 64px;
    @media (max-width: 599px) {
      font-size: 40px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 40px;
    }
  }

  > p {
    color: white;
    text-align: center;
    padding-bottom: 24px;
    font-size: 40px;
    line-height: 34.66px;
    @media (max-width: 599px) {
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 13px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 28px;
    }
  }

  .question-button {
    width: 150px;
    align-content: center;
    margin: 0 auto;
  }
`;

const CenterTextWrapper = styled.div`
  text-align: center;
  @media (max-width: 599px) {
    padding: 0;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0;
  }

  p {
    margin: 30px 0 50px;
  }
`;
export default IndexPage;

export const Head = () => (
  <SEO />
)
