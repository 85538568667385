import React from "react";
import styled from "styled-components";
import UsdArrived from "../../assets/usd-arrived.svg";
import PiesAndSlices from "../../assets/pies-and-slices-2.svg";
import ComissionFree2 from "../../assets/commission-free-2.svg";
import Leaf1 from "../../assets/leaf2-min.svg";
import Leaf4 from "../../assets/leaf4-min.svg";
import Leaf8 from "../../assets/leaf8-min.svg";
import VideoPlayer from "../VideoPlayer";

type Props = {
  img?: string;
  leaf?: string;
  onDesktopPositionTextTo?: "left" | "right";
  data: {
    paragraphs?: { content: string; type: "bold" | undefined }[];
    title?: string;
  };
  video?: {
    src: string;
    type: "video/mp4" | "video/ogg";
    poster: string;
  };
};

const TwoColumns = ({
  img,
  leaf,
  data,
  video,
  onDesktopPositionTextTo = "right",
}: Props) => {
  const Wrapper = styled.section`
    .content {
      max-width: 1218px;
      margin: 0 auto;
      display: flex;
      flex-flow: row-reverse;
      align-items: center;
      position: relative;

      @media (max-width: 599px) {
        margin: 0 auto;
        padding: 0;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }

      @media (max-width: 1024px) {
        padding: 0;
      }
    }

    .leaf-1 {
      bottom: 570px;
      left: 0;
      width: 320px;
      position: absolute;
      z-index: -1;
    }

    .leaf-8 {
      bottom: -630px;
      right: 0;
      position: absolute;
      z-index: -1;
      @media (min-width: 441px) {
        bottom: -980px;
        width: 100%;
      }
      @media (min-width: 1025px) {
        display: none;
      }
    }

    .leaf-4 {
      bottom: 701px;
      right: 0;
      width: 303px;
      z-index: -1;
      position: absolute;
    }

    @media (max-width: 599px) {
      margin: 0 auto;
      padding: 0;
      justify-content: center;
      align-items: center;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      display: flex;
      justify-content: space-evenly;
    }
    @media (min-width: 2000px) {
      padding: 100px 0 0;
      display: flex;
      justify-content: space-evenly;
    }

    .textSection {
      width: 50%;
      padding-left: 30px;
      padding-right: 30px;
      @media (max-width: 599px) {
        width: auto;
        order: 0 !important;
      }
      > h2 {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 24px;
        color: black;
        @media (min-width: 1025px) {
          font-size: 2.5rem;
        }
        @media (max-width: 599px) {
          text-align: center;
        }
      }

      > p {
        margin-top: 15px;
        font-size: 18px;
        line-height: 22px;
        color: black;
        @media (min-width: 1025px) {
          line-height: 34px;
        }
        @media (max-width: 599px) {
          margin-top: 0px;
          width: auto;
          margin-bottom: 0;
          text-align: center;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          margin-bottom: 77px;
        }
        &.font-weight-bold {
          font-weight: bold;
        }
      }
    }

    .imageSection {
      width: 50%;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      justify-content: center;
      position: relative;
      @media (max-width: 599px) {
        width: auto;
        margin-top: 25px;
      }
    }
    .phone-margin {
      max-width: 247px;
      border: 10px solid #e9e7fe;
      border-radius: 36px;
    }
    .mock-up {
      margin: 20px;
      margin-bottom: 0;
      @media (max-width: 599px) {
        margin: 0;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        margin: 20px;
      }
      @media (max-width: 2560px) and (min-width: 1025px) {
        width: 25rem;
      }
    }
    .mock-up-video {
      max-width: 276px;
      margin-bottom: 60px;
      @media (min-width: 1025px) {
        margin-bottom: 0;
      }
    }
  `;

  const getImage = () => {
    switch (img) {
      case "1":
        return { src: PiesAndSlices, alt: "Pies and slices" };
      case "2":
        return { src: ComissionFree2, alt: "Commisstion Free" };
      case "6":
        return { src: UsdArrived, alt: "USD arrived" };
    }
  };

  const showLeaf = () => {
    switch (leaf) {
      case "1":
        return <img className="leaf-1" src={Leaf1} alt="" />;
      case "4":
        return <img className="leaf-8" src={Leaf8} alt="" />;
      case "5":
        return <img className="leaf-4" src={Leaf4} alt="" />;
      default:
        break;
    }
  };

  return (
    <div data-aos="fade-up" data-aos-anchor-placement="top-center">
      <Wrapper>
        {showLeaf()}
        <div className="content">
          <div
            className="textSection"
            style={{ order: onDesktopPositionTextTo === "right" ? "0" : "1" }}
          >
            <h2>{data.title}</h2>
            {data?.paragraphs?.length &&
              data?.paragraphs.map(
                (singleParagraphs: {
                  content: string;
                  type: "bold" | undefined;
                }) => {
                  return (
                    <p
                      className={
                        singleParagraphs.type === "bold"
                          ? "font-weight-bold"
                          : ""
                      }
                    >
                      {singleParagraphs.content}
                    </p>
                  );
                }
              )}
          </div>
          <div
            className="imageSection"
            style={{ order: video?.src ? "-1" : "0" }}
            data-aos="fade-right"
            data-aos-duration="800"
          >
            {getImage() && (
              <img
                className="mock-up phone-margin"
                src={getImage()?.src}
                alt={getImage()?.alt}
              />
            )}
            {video?.src && (
              <VideoPlayer
                className="mock-up-video phone-margin"
                src={video.src}
                type={video.type}
                poster={video.poster}
              />
            )}
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default TwoColumns;
