import React from "react";
import styled from "styled-components";

interface Props {
  brand: {
    img: string,
    symbol: string,
    name: string,
    price: string,
    change: number | undefined,
    percent: string
  }
}

export const BrandInfo = ({ brand = {
    img: "",
    symbol: "",
    name: "",
    price: "",
    change: undefined,
    percent: ""
} }: Props) => {
  return (
    <Wrapper>
      <div className="container">
        <div className="logo">
          <img src={brand.img} alt={`${brand.name} stock`}/>
          <div>
            <p>{brand.symbol}</p>
            <span>{brand.name}</span>
          </div>
        </div>
        <div className="value">
          <h3>${brand.price}</h3> <p>USD</p>
        </div>
        <div className="percentage">
          {brand.change > 0 ? (
            <p>
              <span>
                ▲ {brand.change} ({brand.percent}%)
              </span>
            </p>
          ) : (
            <p>
              <span className="red-color">
                ▼ {brand.change} ({brand.percent}%)
              </span>
            </p>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin: 0 40px;
  background: #ffffff;
  box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  border-radius: 20px;
  width: 256px;
  height: 251px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    width: 243px;
    height: 207px;
  }
  .logo {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 15px;
    p {
      font-size: 20px;
      color: #353945;
      font-weight: 500;
    }
    span {
      font-size: 12px;
      color: #c1c0ce;
    }
    img {
      width: 50px;
      border-radius: 50%;
    }
  }

  .value {
    display: flex;
    align-items: flex-end;
    gap: 9px;
    p {
      font-weight: 500;
      font-size: 1rem;
    }
    h3 {
      font-size: 1.9rem;
      line-height: 1.22;
    }
  }

  .percentage {
    gap: 8px;
    display: flex;
    align-items: center;
    color: #47ac98;
    p {
      font-size: 0.7rem;
    }
    .arrow {
      width: 15px;
    }
  }
  .red-color {
    color: #e82127;
  }
`;
