import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Header } from "../../header/header";
import * as styles from "./home.module.sass";
import PartyPopper from "../../../assets/party-popper-min.svg";
import { Link as LinkScroll } from "react-scroll";
import { Link } from "gatsby";

export const Home = (props: any) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Flahmingo Investments</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no, maximum-scale=1, viewport-fit=cover"
        />
      </Helmet>
      <div>
        <Header />
        <div>{props.children}</div>
        <Link
          to="https://fly.flahmingo.com/Web"
          spy={true}
          smooth={true}
          className={styles.join_button}
        >
          Join Now
        </Link>
      </div>
    </>
  );
};
