import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import Leaf5 from "../../assets/leaf-5.svg";
import Leaf6 from "../../assets/leaf-6.svg";
import {BrandInfo} from "../BrandsInfo/brandInfo";
import {useQuery} from "@apollo/client";
import {getStockQuote} from "../../queries";

type Prop = {
  mustShowGradient?: boolean;
}

export const MonitorWrapper = ({mustShowGradient = false}: Prop) => {
  const [isGoogStockVisible, setIsGoogStockVisible] = useState(false)
  const [isAmznStockVisible, setIsAmznStockVisible] = useState(false)
  const [isTslaStockVisible, setIsTslaStockVisible] = useState(false)
  const [isNflxStockVisible, setIsNflxStockVisible] = useState(false)
  const [isNkeStockVisible, setIsNkeStockVisible] = useState(false)
  
  const Container = styled.section`
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    > img {
      position: absolute;
    }
    .right-leaf {
      right: 0;
      top: -150px;
      z-index: -1;
      @media (max-width: 599px) {
        display: none;
      }
      @media (min-width: 1600px) {
        width: 200px;
        top: -100px;
      }
    }
    .left-leaf {
      left: 0;
      top: -350px;
      @media (min-width: 1600px) {
        width: 300px;
        top: -200px;
        z-index: -1;
      }
      @media (max-width: 599px) {
        display: none;
      }
    }
    .marquee-wrapper {
      max-width: 100%;
      @media (min-width: 1600px) {
        max-width: 50%;
      }
    }
  `;
  const Wrapper = styled.div`
    z-index: 0;
    .brand {
      float: left;
      transition: all 0.2s ease-out;
      &:hover {
        cursor: pointer;
      }
    }
  `;

  const {error: googStockError, data: googStock} = useQuery(getStockQuote, {
    variables: {symbol: 'GOOG'},
  });

  useEffect(()=>{
   setIsGoogStockVisible(!googStockError && googStock?.getStockQuote?.quote?.currentPrice) 
  }, [googStockError, googStock])

  const {error: amznStockError, data: amznStock} = useQuery(getStockQuote, {
    variables: {symbol: 'AMZN'},
  });

  useEffect(()=>{
    setIsAmznStockVisible(!amznStockError && amznStock?.getStockQuote?.quote?.currentPrice) 
   }, [amznStockError, amznStock])

  const {error: tslaStockError, data: tslaStock} = useQuery(getStockQuote, {
    variables: {symbol: 'TSLA'},
  });

  useEffect(()=>{
    setIsTslaStockVisible(!tslaStockError && tslaStock?.getStockQuote?.quote?.currentPrice) 
   }, [tslaStockError, tslaStock])

  const {error: nflxStockError, data: nflxStock} = useQuery(getStockQuote, {
    variables: {symbol: 'NFLX'},
  });

  useEffect(()=>{
    setIsNflxStockVisible(!nflxStockError && nflxStock?.getStockQuote?.quote?.currentPrice) 
   }, [nflxStockError, nflxStock])

  const {error: nkeStockError, data: nkeStock} = useQuery(getStockQuote, {
    variables: {symbol: 'NKE'},
  });

  useEffect(()=>{
    setIsNkeStockVisible(!nkeStockError && nkeStock?.getStockQuote?.quote?.currentPrice) 
   }, [nkeStockError, nkeStock])

  return (
    <Container>
      <img className="left-leaf" src={Leaf6} alt="" />
      <Wrapper className="marquee-wrapper">
        <Marquee gradient={mustShowGradient || false} speed={60} className="z-index">
          { isGoogStockVisible &&
            <div className="brand">
              <BrandInfo brand={{
                name: "Alphabet",
                symbol: "GOOG",
                price: googStock?.getStockQuote?.quote?.currentPrice,
                change: googStock?.getStockQuote?.quote?.change,
                percent: googStock?.getStockQuote?.quote?.changePercent,
                img: googStock?.getStockQuote?.quote?.image,
              }} />
            </div>
          }
          { isAmznStockVisible &&
          <div className="brand">
            <BrandInfo brand={{
              name: "Amazon.com .",
              symbol: "AMZN",
              price: amznStock?.getStockQuote?.quote?.currentPrice,
              change: amznStock?.getStockQuote?.quote?.change,
              percent: amznStock?.getStockQuote?.quote?.changePercent,
              img: amznStock?.getStockQuote?.quote?.image,
            }} />
          </div>
          }
          { isTslaStockVisible &&
          <div className="brand">
            <BrandInfo brand={{
              name: "Tesla",
              symbol: "TSLA",
              price: tslaStock?.getStockQuote?.quote?.currentPrice,
              change: tslaStock?.getStockQuote?.quote?.change,
              percent: tslaStock?.getStockQuote?.quote?.changePercent,
              img: tslaStock?.getStockQuote?.quote?.image,
            }} />
          </div>
          }
          {isNflxStockVisible && 
            <div className="brand">
              <BrandInfo brand={{
                name: "Netflix",
                symbol: "NFLX",
                price: nflxStock?.getStockQuote?.quote?.currentPrice,
                change: nflxStock?.getStockQuote?.quote?.change,
                percent: nflxStock?.getStockQuote?.quote?.changePercent,
                img: nflxStock?.getStockQuote?.quote?.image,
              }} />
            </div>
          }
          {isNkeStockVisible && <div className="brand">
            <BrandInfo brand={{
              name: "Nike",
              symbol: "NKE",
              price: nkeStock?.getStockQuote?.quote?.currentPrice,
              change: nkeStock?.getStockQuote?.quote?.change,
              percent: nkeStock?.getStockQuote?.quote?.changePercent,
              img: nkeStock?.getStockQuote?.quote?.image,
            }} />
          </div>}

        </Marquee>
      </Wrapper>
      <img className="right-leaf" src={Leaf5} alt="" />
    </Container>
  );
};