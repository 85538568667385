// extracted by mini-css-extract-plugin
export var MonitorWrapper = "index-module--MonitorWrapper--4fca6";
export var bold = "index-module--bold--77cda";
export var education = "index-module--education--26dff";
export var fractionalSharesP = "index-module--fractionalSharesP--840d5";
export var leaf7 = "index-module--leaf7--507ce";
export var paragraph = "index-module--paragraph--4448c";
export var section = "index-module--section--59053";
export var title = "index-module--title--889cb";
export var titleEmpowered = "index-module--titleEmpowered--776e8";
export var titleFractionalShares = "index-module--titleFractionalShares--e3ad2";
export var titleMagic = "index-module--titleMagic--58498";
export var usdAccounts = "index-module--usdAccounts--624f3";