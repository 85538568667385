import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";

export const client = new ApolloClient({
  uri: process.env.GATSBY_FLAHMINGO_API,
  cache: new InMemoryCache(),
});

export default {
  ApolloProvider,
  client,
};