import React from "react";
import styled from "styled-components";

interface IMinimalButtonProps {
  onClick: () => void;
  children: JSX.Element | JSX.Element[] | string | string[];
  color?: "primary" | "secondary" | "white";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  style: any;
}

const Button = ({
  onClick,
  children,
  style,
  size = "small",
}: IMinimalButtonProps) => {
  const Wrapper = styled.section`
    background-color: #ff63af;
    color: white;
    border-radius: 60px;
    height: ${size === "small" ? "45px" : "45px"};
    cursor: pointer;
    p {
      font-size: 18px;
      padding: ${size === "small" ? "6px 24px;" : "6px 24px;"};
      @media (max-width: 1024px) {
        padding: 8px 24px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
    }
  `;

  return (
    <Wrapper style={style} onClick={onClick}>
      <p>{children}</p>
    </Wrapper>
  );
};

export default Button;
