import React, { useEffect } from "react";
import "./style.scss";
import MyPies from "../../assets/my-pies.svg";
import Particles from "../../library/particles";
import Image from "../../assets/Tesla.png";
import Image8 from "../../assets/Tesla.png";
import Image2 from "../../assets/At&t.png";
import Image3 from "../../assets/Paypal.png";
import Image7 from "../../assets/Paypal.png";
import Image4 from "../../assets/Disney.png";
import Image5 from "../../assets/Nike.png";
import Image6 from "../../assets/Uber.png";
import PlayStore from "../../assets/download-play-store-min.svg";
import AppStore from "../../assets/app-store-download-min.svg";

export const Hero = () => {
  useEffect(() => {
    const demoParticles = document.querySelector(".cb-particles");
    const particles = new Particles({
      container: demoParticles,
      itemsSelector: ".cb-particles-item",
    });
  }, []);
  return (
    <div className="hero" id="hero" data-aos="fade-up" data-aos-duration="900">
      <section className="cb-demo scroll-content">
        <div className="cb-particles">
          <div className="cb-particles-item -text -v1 -s4">
            <img src={Image} alt="Tesla" className="hero__image" />
          </div>
          <div className="cb-particles-item -text -v1 -s4">
            <img src={Image} alt="Tesla" className="hero__image" />
          </div>
          <div className="cb-particles-item -text -v2 -s4">
            <img src={Image2} alt="Tesla" className="hero__image" />
          </div>
          <div className="cb-particles-item -text -v3 -s4">
            <img src={Image3} alt="Paypal" className="hero__image" />
          </div>
          <div className="cb-particles-item -text -v4 -s4">
            <img src={Image4} alt="Tesla" className="hero__image" />
          </div>
          <div className="cb-particles-item -text -v5 -s4">
            <img src={Image5} alt="Paypal" className="hero__image" />
          </div>
          <div className="cb-particles-item -text -v6 -s4">
            <img src={Image6} alt="Tesla" className="hero__image" />
          </div>
          <div className="cb-particles-item -text -v7 -s4">
            <img src={Image7} alt="Paypal" className="hero__image" />
          </div>
          <div className="cb-particles-item -text -v8 -s4">
            <img src={Image8} alt="Tesla" className="hero__image" />
          </div>
        </div>
        <div className="cb-demo-content">
          <div className="hero__section">
            <div className="hero__section__title">
              <h1>Investing as easy as </h1>
              <h1 className="secondary-color">Pies and Slices.</h1>
              <div className="hero__section__title__description" id="download">
                <p>
                  Make empowered investing decisions with commission-free
                  fractional investing.
                </p>
                <p className="download">Get the App.</p>
                <div className="hero__section__download" id="downloadMobileScroll">
                  <a href="https://play.google.com/store/apps/details?id=development.flahmingo.app">
                    <img src={PlayStore} alt="PlayStore Logo" />
                  </a>
                  <a href="https://apps.apple.com/ca/app/flahmingo/id1529127053">
                    <img src={AppStore} alt="AppStore Logo" />
                  </a>
                </div>
              </div>
              <section className="hero-animation">
                <img
                  className="hero-phone-1 phone-margin"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  src={MyPies}
                  alt="Flahmingo pies"
                />
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};