import React from "react";

React;
interface Props {
  src: string;
  type: "video/mp4" | "video/ogg";
  className?: string;
  poster?: string;
}
export default function VideoPlayer({
  src,
  type,
  className = "",
  poster = "",
}: Props) {
  return (
    <video className={className} poster={poster} controls autoPlay muted>
      <source src={src} type={type} />
    </video>
  );
}
