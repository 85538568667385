export const constants: any = {
  piesAndSlices: {
    title: "Pies and Slices",
    paragraphs: [
      {
        type: "bold",
        content:
          "The sugar and spice of Flahmingo. A Pie is your portfolio, and a Slice is a stock or ETF.",
      },
      {
        type: "plain",
        content:
          "Create up to 10 Pies and classify each Pie any way you want. Each Pie can have up to 100 Slices, which means you can invest in 100 stocks in each of your Pies.",
      },
      {
        type: "plain",
        content: "The best part: you only need $1 USD to invest in one stock.",
      },
    ],
  },
  learn: {
    title: "Flahmingo Central",
    paragraphs: [
      {
        type: "plain",
        content:
          "Learn the basics and complexities of investing right in the app with Flahmingo Central. Feel financially empowered to make informed investment decisions for you and your future.",
      },
      {
        type: "plain",
        content:
          "Our video-based educational hub simplifies confusing defintions and concepts, so you can get the answers you need in one place.",
      },
    ],
  },
  comissionFree: {
    title: "Commission Free",
    paragraphs: [
      {
        type: "plain",
        content:
          "Invest in fractional shares as many times as you like, commission free. Put the $5, $10 or $20 you would’ve paid in commissions back in your pocket (or your portfolio).",
      },
      {
        type: "plain",
        content: "There’s no catch and no commissions.",
      },
    ],
  },
  usdAccounts: {
    title: "USD Accounts",
    paragraphs: [
      {
        type: "plain",
        content:
          "Hold USD in your USD cash account, so you can buy or sell U.S. securities without the FX fees. Only pay FX fees when you convert your funds from CAD to USD or USD to CAD.",
      },
      {
        type: "plain",
        content: "No premium account required.",
      },
    ],
  }
};
