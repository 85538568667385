import React from "react";
import { useSiteMetadata } from "../../hooks/use-site-metadata";

type Props = {
  title?: string;
  description?: string;
  pathname?: string;
  children?: any;
};

export const SEO = ({ title, description, children }:Props) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      {children}
    </>
  );
};
