import {gql} from "@apollo/client";

export const getStockQuote = gql`
  query getStockQuote($symbol: String!) {
    getStockQuote(symbol: $symbol) {
      quote {
        change
        changePercent
        currentPrice
        image
      }
    }
  }
`;